// مرتب سازی از قیمت کم به زیاد
export function sortVarieties(product) {
  if (!product.varieties) {
    return []
  }
  return [...product.varieties].sort((item1, item2) => {
    return (item1.final_price.amount) - (item2.final_price.amount)
  })
}

export function getImages(product) {
  // let varieties = sortVarieties(product)
  let varieties = product.varieties
  if (product.images != '') {
    return product.images
  } else {
    let images = []
    for (let i = 0; i < varieties.length; i++) {
      if (varieties[i].images != '') {
        images = varieties[i].images
        break;
      }
    }
    return images
  }
}


// فیلتر کردن شیپینگ ها بر اساس آدرس
export function setShipping(address, shippings) {
  let shipping = [];
  shippings.forEach((el) => {
    if (el.provinces != "") {
      if (
          el.cities != "" && el.cities.filter(
          (city) => city.province_id == address.city.province_id
          ) != ""
      ) {
        if (el.cities.find((p) => p.id == address.city_id)) {
          shipping.push(el);
        }
      } else if (
          el.provinces.filter(
              (province) => province.id == address.city.province_id
          ) != ""
      ) {
        shipping.push(el);
      }
    } else {
      shipping.push(el);
    }
  });
  return shipping;
}

// Reservation => Order
export function getShippingPrice(address, shipping, amount, quantity, isReserved = false, reservation = null, customer = null) {
  if (customer) {
    const roleId = customer.role_id
    const customerRole = shipping.customer_roles.find(cR => {
      return cR.pivot.customer_role_id == roleId && cR.pivot.shipping_id == shipping.id
    })

    if (customerRole) {
      return customerRole.pivot.amount
    }
  }
  if (reservation) {
    let totalTotalQuantity = reservation.total_total_quantity + quantity;
    let totalPackets = Math.ceil(Number.parseFloat(totalTotalQuantity) / shipping.packet_size);

    return (reservation.shipping_packet_amount + (totalPackets - 1) * reservation.shipping_more_packet_price) - reservation.shipping_amount;
  }
  if (!shipping) {
    return 0
  }
  let totalPackets = Math.ceil(Number.parseFloat(quantity) / shipping.packet_size);
  let additionalPrice = (totalPackets - 1) * shipping.more_packet_price;

  let check = () => {
    if (shipping.provinces != '') {
      let shippingItem;
      shippingItem = shipping.cities.find((city) => {
        return city.id == address.city.id;
      });
      if (shippingItem) {
        return shippingItem.pivot.price;
      }
      shippingItem = shipping.provinces.find((province) => {
        return province.id == address.city.province_id;
      })
      if (shippingItem) {
        return shippingItem.pivot.price
      } else {
        return shipping.default_price;
      }
    } else {
      return shipping.default_price;
    }
  }
  if (shipping.free_threshold) {
    if (shipping.free_threshold >= amount) {
      if (customer && customer.role_id) {
        const customerRole = shipping.customer_roles.find(c => {
          return c.pivot.customer_role_id == c.id
        })

        if (customerRole) {
          return customerRole.pivot.amount
        }
      }
      return check() + additionalPrice
    } else {
      return 0;
    }
  } else {
    return check() + additionalPrice
  }
}


// پیدا کردن قیمت سفارش در لیست فاکتور ها

export function findPriceOrder(list) {
  let amount = null;
  list.forEach(element => {

    if (element.status.toLowerCase() == "success") {
      amount = element.amount;
      return;
    }

  });
  return amount;
}

// دریافت مجموع قیمت براساس قیمت تخفیف و تعداد
export function getSumPrice(price, discount, count) {
  if (discount == null) {
    discount = 0
  }
  return ((price * count) + (discount * count));
}

// پیدا کردن نحوه پرداخت
export function findPayments(type, paymentObject) {
  if (type == "gateway") {
    let output = "درگاه " + paymentObject.gateway_label
    return output
  } else if (type == 'wallet') {
    return "کیف پول"
  } else if (type == 'both') {
    return 'کیف پول و درگاه'
  } else {
    return 'نامعلوم'
  }
}

// دریافت وضعیت استاتوس هر سفارش
export function getOrderStatus(status) {

  if (status.toUpperCase() == "NEW") {
    return "جدید";
  } else if (status.toUpperCase() == "CANCELED") {
    return "لغو شده";
  } else if (status.toUpperCase() == "IN_POROGRESS") {
    return "در دست اقدام";
  } else if (status.toUpperCase() == "DELIVERED") {
    return "تکمیل شده";
  } else if (status.toUpperCase() == "WAIT_FOR_PAYMENT") {
    return "در انتظار پرداخت";
  } else if (status.toUpperCase() == "RESERVED") {
    return "رزور شده";
  } else if (status.toUpperCase() == 'FAILED') {
    return 'شکست خورده';
  }
}

export function getStatusColor(name) {
  switch (name) {
    case 'canceled':
      return '#c21717'
    case 'new':
      return '#36f';
    case 'wait_for_payment':
      return '#f7bf0d'
    case 'reserved':
      return '#a211f5'
    case 'delivered':
      return '#0dcd94'
    case 'in_progress':
      return '#9c9c9c';
    case 'failed':
      return '#c21717';
  }
}

// دریافت وضعیت استاتوس هر فاکتور
export function getInvoicesStatus(status) {

  if (status.toUpperCase() == "SUCCESS") {
    return "پرداخت شده";
  } else if (status.toUpperCase() == "PENDING") {
    return " در انتظار پرداخت";
  } else if (status.toUpperCase() == "FAILED") {
    return " ناموفق";
  }
}

export function cartListsOpen() {

  let cartLists = document.getElementsByClassName("cart-lists")[0];
  const cartListsCloseResp = document.getElementsByClassName(
      "cart-lists-close-resp"
  )[0];
  // let cartFixed=document.getElementsByClassName('cart-fixed')[0];
  // cartFixed.style.left="-150px"
  cartListsCloseResp.style.display = "block";
  cartLists.style.left = "0";
}

export function pay(makeResponse) {
  console.log(makeResponse)
  if (makeResponse.method === 'GET' || makeResponse.method === 'get') {
    let inputs = makeResponse.inputs;
    let params = '';
    if (inputs && typeof inputs === 'object') {
      Object.entries(inputs).forEach(([key, input],index) => {
        if (index === 0) {
          params += '?' + key + '=' + input;
        } else {
          params += '&' + key + '=' + input;
        }
        index++;
      })
    }

    window.location.href = makeResponse.url + params;
  } else {
    let form = document.createElement('form');
    form.method = makeResponse.method;
    form.action = makeResponse.url;
    if (makeResponse.inputs && Object.entries(makeResponse.inputs).length) {
      Object.entries(makeResponse.inputs).forEach((keyVal) => {
        let key = keyVal[0];
        let value = keyVal[1];
        let input = document.createElement('input')
        input.name = key;
        input.type = 'hidden';
        input.value = value;
        form.appendChild(input);
      });
    }
    document.querySelector('body').appendChild(form);
    form.submit()
  }
}

export function isSafari() {
  let ua = window.navigator.userAgent;
  let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  let webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
}

export function openSearch() {
  const searchBox = document.querySelectorAll(".main-search")[0];
  searchBox.dispatchEvent(new Event('open'));
  let mainSearchResp = document.querySelectorAll(".main-search-close-resp")[0];
  searchBox.classList.toggle("show-main-search");
  if (mainSearchResp.style.display == "block") {
    mainSearchResp.style.display = "none";
  } else {
    mainSearchResp.style.display = "block";
    document.getElementById('searchInput').focus();
  }
}

// Size: sm,md,lg
export function getImage(image, size = null) {
  if (!image) {
    return null
  }
  if (!size) {
    return image.url
  }
  return image.conversions && image.conversions[size] ? image.conversions[size] : image.url;
}
